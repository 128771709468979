
nav {
  box-shadow: none !important;
  position: absolute !important;
  .logo-wrapper {
    position: absolute;
    .icon--logo, .icon--logo_weiss{
      position: absolute;
      top: 14px;
      z-index: 10;
       @include small-and-down {
        top: 7px;
        transform: scale(.7);
      }
      @include mobile-and-landscape {
        top: 7px;
        transform: scale(.7);
      }
    }
  }

  ul {

    a {

      &:hover {
        background-color: rgba(0,0,0,0);
      }
    }

     a.active {
      border-bottom: 1px solid;
      position: relative;
      border-color: #fff;
      transform: translateY(-1px);
    }

    a.hvr-underline-from-center {
      text-decoration: none;
      transform: perspective(1px) translateZ(0);
      position: relative;
      overflow: hidden;
      
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 51%;
        right: 51%;
        bottom: 0;
        background: #fff;
        height: 1px;
        transition-property: left, right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
      }
      &:hover:before, &:focus:before, &:active:before {
        left: 0;
        right: 0;
      }
    }

    &.grey-a {

      li >  a {
        color: $color_3;

        &.active {
          border-color: #000;
          }

        &.hvr-underline-from-center {
          &:before {
            background: #000;
          }
        }
       }
      }
  }
  
  .sidenav-trigger {
    color: $color_3;
    float: right;
  }

  
}
.sidenav {
  padding-top: 50px;
  li > a {
    font-size: 1.2rem !important;
 }
}

