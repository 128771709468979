.more_vert {
	opacity: 0;
	transition: 0.4s;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: block;
	margin:0.4em auto;
}

