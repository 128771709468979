.liveValidateMessage {
  color: red;
  transition: all .5s ease-out;
  font-size: 12px;
  z-index: 1;
  opacity: 0;
  transform: translateY(100%);
  display: block;
  position: absolute;

}

.liveValidateMessage--visible {
  position: absolute;
  opacity: 1;
  transform: translateY(0)
}

.input-field input[type=text]:focus {
  box-shadow: none !important;
}

.input-field textarea[type=text]:focus {
  box-shadow: none !important;
}

#progressbar {
  display: none;
}

.city {
  display: none;
}

.card-panel{
  background-color: $success-color;
}

.col.form-group {
  margin-top: 20px;
  margin-bottom: 25px
}