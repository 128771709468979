//Section: Hero Image Homepage
.home-header {
  background: url(../images/ppw/hero.jpg);
  background-size: cover;
  background-position: bottom left;
  max-width: 100%;
  height: 100vh;
}

.container-home-hero {
  .hero-text {
		@include mobile-and-landscape{
			padding-top: 70px;
			a.btn-floating {
				visibility:hidden;
			}
			.hero-claim {
				font-size: 2rem !important;
			}
		}

    @include small-and-down {
			padding-top: 90px;
      display: flex;
			flex-direction: column;
			a.btn-floating {
				visibility:hidden;
			}
    }
    
    @include medium-and-up-portrait {
			padding-top: 150px;
		}

		@include large-and-up {
      padding-top: 150px;
		}		

    @include extra2x-large-and-up {
      padding-top: 120px;
		}
		
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%
    );

    p {
      width: 75%;
    }
  }
}

.scroll-icon {
  position: absolute;
  bottom: 5%;
  left: 47%;
  opacity: 0.5;

  span {
    pointer-events: none;
  }
}

/** #### Section: Das sind wir! #### */
///////////////////////////////////////////
.section-home-main {
  background: linear-gradient(
    146deg,
    rgba(188, 189, 188, 0.2) 1%,
    rgb(255, 255, 255) 20%
  );
  @include small-and-down {
    padding-top: 75px;
  }
}

.dassindwir-carousel {
  .col.original-auto-ersatzteile,
  .col.produktvielfalt {
    opacity: 0.9;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    background-position: center center;
  }

  .card-text {
    box-shadow: 5px 5px 18px 0 rgba(0, 0, 0, 0.05);
    padding: 1.6rem;
    @include medium-and-up {
      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 99;
      }
      &:before {
        background: rgba(181, 174, 175, 0.1);
        clip-path: polygon(20% 0, 100% 0, 100% 30%, 40% 70%);
        mix-blend-mode: multiply;
      }
      &:after {
        background: rgba(113, 104, 106, 0.2);
        clip-path: polygon(40% 70%, 100% 30%, 100% 100%, 48.5% 100%);
        mix-blend-mode: multiply;
      }
    }
  }

  .aling-col-inner {
    padding: 15px;
  }

  @include medium-and-up {
    .row.aling-row-outer {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @include extra-large-and-up {
    .aling-col-inner {
      padding: 15px 60px 15px 60px;
    }
  }

  .owl-controls {
    padding-top: 30px;
  }
}

// .col.aling-col-inner {
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// }

//////////////////////////////////////////////////////
/** #### Section: Unser Leistungsversprechen ##### */

.card.card-leistungsversprechen {
  box-shadow: 0 0.07143rem 0.42857rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.64286rem;
  flex-flow: column;
  @include small-and-down {
    max-height: 460px;
  }

  & .gitter {
    width: 75px;
    height: 75px;
    margin: auto;
    position: relative;
  }
}

.leistungsversprechen-carousel {
  padding-top: 20px;
  .owl-item {
    padding: 15px;
  }
}

/////////////////////////////////////////////////
// ## Section: Parallex interferer_1 ##
.parallax-container.custom-parallax {
  height: 200px;

  img {
    background-position: center bottom;
    background-size: cover;
    height: auto;
    width: auto;
  }

  @include medium-and-up {
    height: 300px;
  }
  @include large-and-up {
    height: 400px;
  }

  @include extra-large-and-up {
    height: 500px;
  }

  @include extra2x-large-and-up {
    height: 600px;
    img {
      max-width: 100%;
    }
  }
}

// ## Section Vorteile ##

.equal-height-row {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.featured {
  overflow: hidden;
}

.featured li {
  float: left;
  position: relative;
  padding: 65px 35px 60px;
  text-align: center;
}

.featured.grid-3 li {
  width: 33.33%;

  @include medium-and-down {
    width: 50%;
  }

  @include small-and-down {
    width: 100%;
  }
}

.featured.plus-box li::before {
  height: 100%;
  top: 0;
  right: -2px;
  border-right: 1px solid #eee;
}

.featured.plus-box li::after,
.featured.plus-box li::before {
  content: "";
  position: absolute;
}

.featured.plus-box li::after {
  width: 100%;
  height: 0;
  top: auto;
  right: 0;
  bottom: -1px;
  border-bottom: 1px solid #eee;
}

.featured.plus-box .icon {
  width: 70px;
  height: 70px;
  margin: auto;
  position: relative;
}

.span-elm {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-link-text {
  font-size: 0.9rem;
}

.featured.plus-box .featured-item:hover .span-elm {
  filter: $accent-color-filter;
  transition: all 0.3s ease;
}

.dassindwir-carousel,
.testimonial-carousel {
  padding-top: 20px;
  position: relative;
  .owl-theme {
    .custom-nav {
      position: absolute;
      bottom: -5px;
      left: 0px;
      right: 0px;
      .prev {
        margin-right: 20px;
        &.wide {
          margin-right: 45px;
        }
      }
      .next {
        margin-left: 20px;
        &.wide {
          margin-left: 45px;
        }
      }
      .icon--keyboard_arrow_left,
      .icon--keyboard_arrow_right {
        filter: invert(60%) sepia(75%) saturate(0%) hue-rotate(247deg)
          brightness(120%) contrast(91%);
      }
    }
  }
}

.cst.owl-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
	align-items: center;
	
}

// ## Section: Interferer_1 ##
.section-interferer-1 {
  position: relative;
  background-size: cover;
  background-position: center;
	min-height: 250px;
	background-color: $background_color_3;
	background-blend-mode: overlay;
}


// ## Section: Product Gallery ##
.container-product-gallery {
  padding-top: 25px;
}

.card-image {
  &:hover {
    .more_vert {
      background-color: $secondary-color;
      opacity: 1;
    }
  }
}

.card.portfolio-card {
  box-shadow: none;
  border: 1px solid #e8e8e8;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(179, 159, 159, 0.2),
      0 6px 20px 0 rgba(154, 149, 149, 0.19);
  }
}

// Hover Effekt
.materialboxed-ppw {
  display: block;
  cursor: -webkit-zoom-in;
  position: relative;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.8;
  }
}

/*Section Brands*/

// .row.row-brands {
// 	 padding-top: 25px;
// 	 padding-bottom: 25px;
// 	 display: flex;
// 	 align-items: center;
// 	 justify-content: center;

// 	& .col {
// 		display: flex;
// 		justify-content: center;
// 		margin: 0;
// 		padding: 0;
// 		position: relative;

// 	}
// 	& .icon-sprite {
// 		margin: 0;
// 		padding: 0;
// 		opacity: 0.2;
// 		@include small-and-down {
// 			transform: scale(.6);
// 		}

// 		@include medium-and-up {
// 			transform: scale(.8);
// 		}

// 		@include extra-large-and-up {
// 			transform: scale(1);
// 		}
// 	}
// }
