

    .icon-sprite  {
      background-image: url('../images/sprites/sprite-aad34c16.svg');
    }
  
  .icon--arrow_forward {
    width: 24px;
    height: 24px;
    background-position: 100% 34.63414634146341%;
  }
  
  .icon--audi {
    width: 71px;
    height: 41px;
    background-position: 31.555555555555557% 79.7872340425532%;
  }
  
  .icon--bmw {
    width: 71px;
    height: 71px;
    background-position: 100% 0;
  }
  
  .icon--chevron_right {
    width: 24px;
    height: 24px;
    background-position: 57.35294117647059% 93.17073170731707%;
  }
  
  .icon--favorite {
    width: 24px;
    height: 24px;
    background-position: 66.17647058823529% 93.17073170731707%;
  }
  
  .icon--hand-holding-heart {
    width: 75px;
    height: 66px;
    background-position: 33.93665158371041% 51.533742331288344%;
  }
  
  .icon--hand-holding-usd {
    width: 40px;
    height: 38px;
    background-position: 87.890625% 74.3455497382199%;
  }
  
  .icon--hand-scissors {
    width: 38px;
    height: 34px;
    background-position: 55.03875968992248% 76.92307692307692%;
  }
  
  .icon--hands {
    width: 47px;
    height: 33px;
    background-position: 90.36144578313252% 36.224489795918366%;
  }
  
  .icon--keyboard_arrow_down {
    width: 30px;
    height: 30px;
    background-position: 38.34586466165413% 95.97989949748744%;
  }
  
  .icon--keyboard_arrow_left {
    width: 30px;
    height: 30px;
    background-position: 67.66917293233082% 75.37688442211055%;
  }
  
  .icon--keyboard_arrow_right {
    width: 30px;
    height: 30px;
    background-position: 99.62406015037594% 71.35678391959799%;
  }
  
  .icon--local_cafe {
    width: 24px;
    height: 24px;
    background-position: 75% 93.17073170731707%;
  }
  
  .icon--logo {
    width: 150px;
    height: 42px;
    background-position: 0 22.459893048128343%;
  }
  
  .icon--logo_weiss {
    width: 150px;
    height: 42px;
    background-position: 0 0;
  }
  
  .icon--menu {
    width: 24px;
    height: 24px;
    background-position: 100% 50.73170731707317%;
  }
  
  .icon--menu_white {
    width: 24px;
    height: 24px;
    background-position: 48.529411764705884% 93.17073170731707%;
  }
  
  .icon--mercedes-benz {
    width: 71px;
    height: 41px;
    background-position: 0 79.7872340425532%;
  }
  
  .icon--question_answer {
    width: 30px;
    height: 30px;
    background-position: 27.06766917293233% 95.97989949748744%;
  }
  
  .icon--rocket {
    width: 37px;
    height: 38px;
    background-position: 0 100%;
  }
  
  .icon--shield-alt {
    width: 35px;
    height: 38px;
    background-position: 14.17624521072797% 100%;
  }
  
  .icon--shipping-fast {
    width: 47px;
    height: 38px;
    background-position: 90.36144578313252% 54.45026178010471%;
  }
  
  .icon--trophy {
    width: 75px;
    height: 66px;
    background-position: 67.87330316742081% 0;
  }
  
  .icon--umbrella-beach {
    width: 75px;
    height: 66px;
    background-position: 0 51.533742331288344%;
  }
  
  .icon--volkswagen {
    width: 72px;
    height: 71px;
    background-position: 66.96428571428571% 41.77215189873418%;
  }
