/** #### Section: Slider & About ####*/
.section-unternehmen {
  z-index: -20;
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  //transform: translateY(-65px);
  position: relative;
  
  .slider {
    height: 60%;

    // @include large-and-up{
    //   height: 75%;
    // }

    // @include extra2x-large-and-up {
    //   height: 59%;
    // }
  
    .slides {
      height: 100%;
      li {
        img {
          width: auto;
          height: 100%;
          // @include mobile-and-landscape {
          //   transform: translateY(0px);
          //   width: 100%;
          //   height: auto;
          // }

          @include medium-and-up {
           width: 100%;
           height: auto;
            @media screen and (orientation : landscape){
              //transform: translateY(-100px);
            }
          }
  
          @include large-and-up {
            width: 100%;
            height: auto;
            transform: translateY(0px);
          }
          
          @include large-and-up-portrait {
            width: auto;
            height: 100%;
          }

          @include extra-large-and-up {
            width: 100%;
            height: auto;
            transform: translateY(-150px);          }
  
          @include extra2x-large-and-up {
            transform: translateY(-50px);
            position: top !important;
            width: 100%;
            height: auto;
          }
        }
  
    .caption {
      width: 90%;
      background-blend-mode: screen;
      margin: 0;
      left: 0;

      &.sli1 {
        top:45%;
      }
      &.sli2 {
        top:55%;
      }
      &.sli3 {
        top: 55%;
      }

      .header-sld {
        background-color: $background_color_1;
        font-size: 1.8rem;
        line-height: 1.5;
        font-weight:700;
        padding-left:15px;
        padding-right:5px;
        box-decoration-break: clone;
      }
      .header-sld-txt {
        padding-left:15px;
        padding-right:5px;
        box-decoration-break: clone;
        background-color: $accent-color;
        font-size: 1.4rem;
        line-height: 1.6;
      }


      @include medium-and-up{
        width: 60%;
        &.sli1 {
          top:45%;
        }
        &.sli2 {
          top:50%;
        }
        &.sli3 {
          top: 60%;
        }
        .header-sld {
          font-size: 3.5rem;
          padding-left:40px;
          padding-right:10px;
        }
        .header-sld-txt {
          padding-left:40px;
          padding-right:10px;
          font-size: 1.6rem;
        }	
      }

      @include mobile-and-landscape {
        width: 60%;
        &.sli1 {
          top:45%;
        }
        &.sli2 {
          top:40%;
        }
        &.sli3 {
          top: 40%;
        }
        .header-sld {
          font-size: 1.6rem;
          padding-left:15px;
          padding-right:5px;
        }
        .header-sld-txt {
          padding-left:15px;
          padding-right:5px;
          font-size: 1.2rem;
        }	
      }

      @include extra-large-and-up{
        width: 55%;
       
        &.sli1 {
          top:40%;
        }
        &.sli2 {
          top:50%;
        }
        &.sli3 {
          top: 50%;
        }
        .header-sld {
          font-size: 3.5rem;
          padding-left:40px;
          padding-right:10px;
        }
        .header-sld-txt {
          padding-left:40px;
          padding-right:10px;
          font-size: 1.6rem;
        }	
      }

      @include extra2x-large-and-up{
        width: 45%;
    
        &.sli1 {
          top:55%;
        }
        &.sli2 {
          top:50%;
        }
        &.sli3 {
          top: 50%;
        }
        .header-sld {
          font-size: 3rem;
          padding-left:60px;
          padding-right:15px;
        }
        .header-sld-txt {
          padding-left:60px;
          padding-right:15px;
          font-size: 1.7rem;
        }	
      }

      }


      }
    }
}

  .indicators {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 25px;
    margin: 0;
    z-index: 2;
    @include mobile-and-landscape {
      bottom: 5px;
    }
    .indicator-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 10px;
      width: 10px;
      margin: 0 6px;
      background-color: rgba(214, 214, 214, 1.000);

      transition: background-color .3s;
      border-radius: 50%;

      @include small-and-down {
        height: 7px;
        width: 7px;
      }

      @include mobile-and-landscape {
        height: 7px;
        width: 7px;
      }

      &.active {
        background-color: $slider-indicator-color;
      }
    }
  }
}
 
/////////////////////////////////
/** Mission*/
.container-mission {
  height: 40%;
  @include mobile-and-landscape {
     height: 30%;
   }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .row {
    margin-bottom: 0px;
  }
  .mission {  
    h1 {
      margin: 0px;
      margin-bottom: 25px;
    }
    .item-mission {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
  }
}


////////////////////////////////////////////////////
/** #### Section: About ####*/
.section-unternehmen-about {
  .tabs {
    margin-top: 25px;
    .tab {
      a {
      
        font-size: 0.9rem;;
        color: $color_3;
        &.active {color: $color_3;}
        &:hover {color: $color_3;}
        &:focus {
          background-color: rgba($color_3, 0.05);
          &.active{
            background-color: rgba($color_3, 0.05);
          }
        }
      }
    }
    .indicator {
      background-color: $accent-color;
    }
  }
  @include medium-and-up {
    .row.aling-row-outer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 25px;
    }
  }
}



//////////////////////////////////////////////////////
/** FAQ*/
.collapsible-header {
  display: block;
  text-align: center;
  border-bottom: 0px;
  .flow-text {  
    &.underline {
      display: inline;
      box-shadow: inset  0 -0.2em rgba($background_color_1, 0.05)
    }
    &:hover {
      box-shadow: inset  0 -0.2em rgba($background_color_1, 1)
    }
  }
}

.collapsible.popout {
    margin-top: 25px;
    text-align: left;
    border: none;
    box-shadow: none;
  > li {
    box-shadow: none;
    border-bottom: 0;
    @include small-and-down {
      margin: 0;
    }
  }
}





// .col.aling-col-inner {
//   padding-right: 60px !important;

// }


// ## Section: Interferer_1 ##
.section-interferer-2 {
  margin-top: 50px;
	position: relative;
	background-size: cover;
	background-position: center;
  height: 250px;
  background-color: $background_color_3;
	background-blend-mode: overlay;
}



// Icons

.icon--question_answer{
  margin-right: 25px;
  filter: invert(62%) sepia(80%) saturate(354%) hue-rotate(60deg) brightness(90%) contrast(87%);
}