// Colors
$color_1: darkblue;
$color_2: #333;
$color_3: black;
$accent-color: #64bc46;
$accent-color-filter: invert(66%) sepia(87%) saturate(314%) hue-rotate(58deg) brightness(82%) contrast(95%);
$success-color: #64bc46 !important;
$secondary-color:#64bc46 !important;
$dropdown-color: #64bc46 !important;


$background_color_1: #031428;
$background_color_2: rgba(0, 0, 0, 0.1);
$background_color_3: rgba(0, 0, 0, 0.7
);
$slider-indicator-color: rgba(134, 151, 145, 1.000);


// Media Queries
$mobile-height: 500px !default;
$small-screen: 600px !default;
$small-screen-up: 601px !default;
$medium-screen: 992px !default;
$medium-screen-up: 993px !default;
$large-screen: 1201px !default;
$extra-large-screen-up: 1381px !default;
$ipadheight: 900px !default;

$small-and-down: "only screen and (max-width : #{$small-screen})" !default;

$mobile-and-landscape: "only screen and (max-width : #{$medium-screen}) and (max-height: #{$mobile-height})" !default;

$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;

$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;

$medium-and-up-portrait: "only screen and (min-width : #{$small-screen-up}) and (min-height: #{$ipadheight})" !default;

$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;

$large-and-up-portrait: "only screen and (min-width : #{$medium-screen-up}) and (min-height: #{$ipadheight})" !default;

$extra-large-and-up: "only screen and (min-width : #{$large-screen})" !default;

$extra2x-large-and-up: "only screen and (min-width : #{$extra-large-screen-up})";


// Mixins


@mixin mobile-and-landscape {
	@media #{$mobile-and-landscape}  { 
		@content }
};

@mixin small-and-down {
	@media #{$small-and-down}  { 
		@content }
};

@mixin medium-and-up {
	@media #{$medium-and-up}   { 
		@content }
};

@mixin medium-and-down {
	@media #{$medium-and-down}   { 
		@content }
};

@mixin medium-only {
	@media #{$medium-only} { 
		@content }
};

@mixin medium-and-up-portrait {
	@media #{$medium-and-up-portrait} { 
		@content}
};

@mixin large-and-up {
	@media #{$large-and-up} { 
		@content }
};

@mixin large-and-up-portrait {
	@media #{$large-and-up-portrait} { 
		@content}
};

@mixin extra-large-and-up {
	@media #{$extra-large-and-up} { 
		@content }
};

@mixin extra2x-large-and-up {
	@media #{$extra2x-large-and-up} { 
		@content}
};





@media (max-width: 500px) {
	.section-developers {
		min-height: 250px;
	}
	.section-podcast {
		min-height: 250px;
	}
}

//Buttons

.btn-flat.btn-cst{
	&.btn-hero {
		width: 200px;
		@include small-and-down {
			width: 100%;
			margin-bottom: 10px;
			display: block !important;
		}
	}
  
  background: $accent-color !important;
  &:hover { 
    background-color: lighten($accent-color, 7%) !important 
  }
  &.btn-cst-snd {
    border: solid 2px !important;
    border-color: $accent-color !important;
    background: #fff !important;
    color: $accent-color !important;
    &:hover {
      color: #fff !important; 
      background-color: $accent-color !important;
    }
  }
}

// Custom Containers

// ##Section: Das sind Wir##

.container {
	&.container-small {
		@include large-and-up {
			width: 65% !important;
			padding-top:25px;
			padding-bottom:50px;
		} 

		@include extra2x-large-and-up {
			width: 60% !important;
			padding-top:75px;
			padding-bottom:100px;
		}
	}

}

.section-padding {
	padding-top:75px;
	padding-bottom:75px;
	// &.section-vorteile {
	// 	padding-bottom: 0px;
	// }
}

.container-padding {
	padding-top: 75px;
	padding-bottom: 75px;
}
