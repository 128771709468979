$font_family_heading: 'Nunito Sans', sans-serif;
$font_family_paragr: 'Nunito Sans', sans-serif;


	$h1-fontsize: 2.4rem !important;
	$h2-fontsize: 1.9rem !important;
	$h3-fontsize: 1.6rem !important;
	$h4-fontsize: 1.4rem !important;
	$h5-fontsize: 1.2rem !important;
	$h6-fontsize: 1rem !important;



	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
h1 { font-size: $h1-fontsize; line-height: 110%; margin: ($h1-fontsize / 1.5) 0 ($h1-fontsize / 2.5) 0;}
h2 { font-size: $h2-fontsize; line-height: 110%; margin: ($h2-fontsize / 1.5) 0 ($h2-fontsize / 2.5) 0;}
h3 { font-size: $h3-fontsize; line-height: 110%; margin: ($h3-fontsize / 1.5) 0 ($h3-fontsize / 2.5) 0;}
h4 { font-size: $h4-fontsize; line-height: 110%; margin: ($h4-fontsize / 1.5) 0 ($h4-fontsize / 2.5) 0;}
h5 { font-size: $h5-fontsize; line-height: 110%; margin: ($h5-fontsize / 1.5) 0 ($h5-fontsize / 2.5) 0;}
h6 { font-size: $h6-fontsize; line-height: 110%; margin: ($h6-fontsize / 1.5) 0 ($h6-fontsize / 2.5) 0;}

a {
  text-decoration: none;
}



	html {
		color: $off-black;
		line-height: 1.5;
		font-family: $font_family_paragr;
		font-weight: 400 !important;
	
		@media only screen and (min-width: 0) {
			font-size: 16px;
		}
	
		@media only screen and (min-width: $small-screen-up) {
			font-size: 14.5px;
		}

		@media only screen and (min-width: $medium-screen-up) {
			font-size: 15px;
		}

		@media only screen and (min-width: $large-screen) {
			font-size: 16px !important;
			p {
				&.prime-p {
					font-size: 20px;
					line-height: 160%;
				}
			}
		}

		@media only screen and (min-width: $extra-large-screen-up) {
			font-size: 18px !important;

			.section-footer {
				font-size: 15px !important;
			}
		}


	

	}
	

	h1, h2, h3, h4, h5, h6 {
		overflow-wrap: break-word;
		font-family: $font_family_heading !important;
		font-weight: 600 !important;

		&.hero-claim {
			font-weight: 700 !important;
			line-height: 120%;
			margin: -10px 0 -10px 0;

			@include small-and-down {
				font-size: 2rem !important;
			}
		}

		&.header-snd {
			font-weight: 700 !important;
			&.underline {
				display: inline;
				padding-right: 10px;
				padding-left: 10px;
				box-shadow: inset  0 -0.7em rgba(100,188,70, 0.2)
			}
		}
	}

	p {
		overflow-wrap: break-word;
		// &.header-snd {
		// 	text-decoration: underline;
		// }
		&.header-b {
			font-size: 1.2rem;
			font-weight: 600;
		}

		&.p-product-head {
			font-weight: 700
		}

		&.p-product-desc {
		line-height: 110%;
		font-weight: 300;
		}
		
		&.ftr-head {
			display: inline;
			padding-right: 10px;
			padding-left: 10px;
			box-shadow: inset 0 -0.7em rgba(100,188,70, 0.2)
		}
	}
