
.kontakt-header {
	background-repeat: no-repeat;
	background-size: cover;
	height: 35vh;
	background: url(../images/kontakt/hero-kontakt-mobile.jpg);
	background-position-y: 75%;


	@include medium-and-up {
		background: url(../images/kontakt/hero-kontakt-small.jpg);
		background-position-y: 75%;
	 }

	 @include large-and-up {
		background: url(../images/kontakt/hero-kontakt-medium.jpg);
		background-position-y: 80%;
	 }

	 @include extra-large-and-up {
		background: url(../images/kontakt/hero-kontakt-large.jpg);
		background-position-y: 95%;
	 }

	 @include extra2x-large-and-up {
		background: url(../images/kontakt/hero-kontakt-extra-large.jpg);
		background-position-y: 100%;
	}

}


.sticky {
	position: sticky;
	top: 5% !important;
}

.container.container-padding {
	padding-top: 100px;
}

.table-of-contents {

  a {

    &:hover {
      border-left: 3px solid $accent-color;
    }
    &.active {
      border-left: 3px solid $accent-color;
    }
  }
}

.not-active {
	pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}