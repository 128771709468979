/***************
    Nav List
***************/
.table-of-contents {
  // &.sticky {
  //     position: sticky;
  //     top: 0.25rem !important;
  //   }

  li {
    padding: 2px 0;
  }
  a {
    display: inline-block;
    font-weight: 300;
    color: #757575;
    padding-left: 16px;
    height: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: .4;
    display: inline-block;

    &:hover {
      color: lighten(#757575, 20%);
      padding-left: 15px;
      border-left: 3px solid $primary-color;
    }
    &.active {
      font-weight: 500;
      padding-left: 14px;
      border-left: 3px solid $primary-color;
    }
  }
}
