
.ppw-footer {
	background-color: $background_color_1;
}
.section-footer{
	padding-top: 50px;
	padding-bottom: 25px;
	.scroll-icon-up {
		position: fixed;
		bottom: 90px;
		right: 50px;
		transform: rotate(180deg);
		visibility: hidden;
		&.btn-floating {
			box-shadow: 0px 0px 2px rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2)
		}
	}
}

.icon--favorite, .icon--local_cafe {
	filter: invert(93%) sepia(9%) saturate(119%) hue-rotate(169deg) brightness(89%) contrast(85%);
}

.btn-ftr {
	margin-bottom: 20px;
}